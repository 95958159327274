import React from 'react';

const TextContent = () => {
	return (
		<section
			className="elementor-element elementor-element-37bac58 elementor-section-stretched elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
			data-id="37bac58"
			data-element_type="section"
			data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
		>
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
					<div
						className="elementor-element elementor-element-cc813c4 elementor-column elementor-col-100 elementor-top-column"
						data-id="cc813c4"
						data-element_type="column"
						data-settings="{&quot;&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
					>
						<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
								<div
									className="elementor-element elementor-element-9cecdef elementor-widget__width-inherit elementor-widget elementor-widget-heading"
									data-id="9cecdef"
									data-element_type="widget"
									data-settings="{&quot;&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="heading.default"
								>
									<div className="elementor-widget-container">
										<h1 className="elementor-heading-title elementor-size-default title-size" id="padding-title-main">Bioavailability & Bioequivalence<br />(BA/BE) Studies</h1>
									</div>
								</div>
								<div
									className="elementor-element elementor-element-04d601f elementor-widget__width-inherit elementor-widget elementor-widget-heading"
									data-id="04d601f"
									data-element_type="widget"
									data-settings="{&quot;&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="heading.default"
								>
									<div className="elementor-widget-container padding-content">

										With diverse methods and molecules, our experience in Bioavailability/ Bioequivalency studies is first-class and have produced valid results that are accepted by the regulatory body. Our company is certified by high-standing institutions and will always give high quality results for the products tested.
									</div>

									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default TextContent;
