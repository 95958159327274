import React from 'react';
import ImgContent from '../imgContent';

const PhotoServices = () => {
	return (
		<section
			className="elementor-element elementor-element-92d239f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
			data-id="92d239f"
			data-element_type="section"
			data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
		>
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
					<div
						className="elementor-element elementor-element-6f1c97b elementor-column elementor-col-100 elementor-top-column"
						data-id="6f1c97b"
						data-element_type="column"
						data-settings="{&quot;&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
					>
						<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
								<div
									className="elementor-element elementor-element-63006b3 animation elementor-widget elementor-widget--image"
									data-id="63006b3"
									data-element_type="widget"
									data-settings="{&quot;&quot;:&quot;false&quot;,&quot;avante_ext_is_smoove&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="image.default"
								>
									<div className="elementor-widget-container">
										<div className="elementor-image image-margin">
											{/* <img
												width={1440}
												height={759}
												src={img1}
												className="attachment-full size-full"
												alt=""
												
											/> */}

											<ImgContent pmlImg="babestudies.png" className="image" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PhotoServices;
